.GList-page{
    min-height: calc(100vh - 60px);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.GList-heading-bar{
    height:35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 8vw;
    align-items: center;
    box-shadow: 0px 0px 10px #00000033 !important;
    background-color: white;
}

.GList-main{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex:1;
}

.Siding-menu{
    width: calc(8vw + 230px);
    padding-left: 8vw;
    margin-top: 10px;
    border-right: 3px solid #00000033;
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.GList-list{
    flex:1;
    margin-right: 8vw;
}

.GList-item{
    height: 250px;
    padding: 20px;
}

.GList-item-image{
    width: 300px;
    height: 210px;
    object-fit: contain;
}

.GList-item-info{
    flex:1;
    height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Good-name{
    font-size: 22px;
    font-weight: 500;
    color: black;
}

.Good-price{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.Good-price-label{
    font-size: 12px;
    font-weight: 500;
    color: black;
}

.Good-price-number{
    font-size: 20px;
    font-weight: 600;
    color: black;
}