.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}



.Home-heading{
	width: 100%;
	background-color: rgb(52, 67, 92);
	padding-bottom: 8px;
	padding-left: 3px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: white;
}

.Home-container{
	max-width: 1500px;

}

.home__row {
	display: flex;
	z-index: 1;
	margin-left: 5px;
	margin-right: 5px;
}

.home__image {
	width: 100%;
	z-index: -1;
	margin-bottom: -150px;
	mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
