.App-header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #131921;
  position: sticky !important;
  top: 0;
  z-index: 100;
}

.App-header__logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 18px;
}

.App-header__search {
  display: flex !important;
  flex: 1 !important;
  align-items: center !important;
  border-radius: 24px !important;
}

.App-header__searchInput {
  min-height: 12px !important;
  padding: 10px !important;
  border: none !important;
  width: 100% !important;
}

.App-header__searchIcon {
  padding: 5px !important;
  min-height: 22px !important;
  background-color: #cd9042;
  font-size: large;
  outline: none;
  border:none;
  border-left:2px solid #cd9042;
  border-right:2px solid #cd9042;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.App-header__searchIcon:active{
  border:2px solid #000000
}

.App-header__optionLineOne {
  font-size: 10px !important;
}

.App-header__optionLineTwo {
  font-size: 13px !important;
  font-weight: 800 !important;
}

.App-header__optionBasket {
  display: flex !important;
  align-items: center !important;
  color: white !important;
}

.App-header__basketCount {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.App-header__nav {
  display: flex;
  justify-content: space-evenly;
}

.App-header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}
